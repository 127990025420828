import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Error = () => {
  return (
    <Layout>
      <SEO title="You seem lost" />
      <main className="error-page">
        <div className="error-container">
          <div className="container">
            <h2>404</h2>
            <h1>
              these aren&apos;t the driods you are looking for...
            </h1>
            <Link to="/" className="btn">
              move along
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Error;
